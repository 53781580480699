export type ApiCallState<T> = {
    error: any,
    payload: T,
    isLoading: boolean,
    isLoaded: boolean
};

export type ActionType = {
    type: string,
    payload: any
}

export const resetApiCallState = (payload: any) => ({
    error: "",
    payload: payload,
    isLoading: false,
    isLoaded: false
});

export const headerAuthentication = () => ({headers: {authorization: "Bearer dsfuiub"}});

export const baseUrl = "http://app.kliqsocial.com:3000/admin"
// export const baseUrl = "http://ec2-3-16-54-139.us-east-2.compute.amazonaws.com:3000/admin"