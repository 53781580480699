import { EyeOutlined } from "@ant-design/icons";
import { Button, Modal, Tabs } from "antd";
import { FC, useState } from "react";
import { User } from "../../Redux/User/User.type";
import AnnouncementList from "../AnnouncementList";
import GroupList from "../GroupList";
import SettingComponent from "../Setting";
import UserDetailComponent from "../UserDetail";
import UserList from "../UserList";

const DetailModal: FC<{ user: User }> = ({ user }) => {
  const [open, setOpen] = useState(false);


  return (
    <>
      <Button type="text" icon={<EyeOutlined />} onClick={() => setOpen(true)}>
        Detail
      </Button>
      <Modal
        visible={open}
        width={1200}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        <Tabs>
          <Tabs.TabPane tab="User Detail">
            <UserDetailComponent user={user} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Annoncements Made" key="1">
            <AnnouncementList announcements={user.announcements} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Annoncements Received" key="2">
            <AnnouncementList announcements={user.uannouncements} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Friends" key="3">
            <UserList users={[...user.friends, ...user.userFriends]} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Groups Created" key="4">
            <GroupList groups={user.groups} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Groups In" key="5">
            <GroupList groups={user.groups} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Setting" key="6">
            <SettingComponent />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default DetailModal;
