import { LikeFilled } from "@ant-design/icons";
import { List } from "antd";
import { FC } from "react";
import { User } from "../Redux/User/User.type";

const UserList: FC<{ users: User[] }> = ({ users }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={users ?? []}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<LikeFilled style={{color: "blue"}} />}
            title={item.username}
          />
        </List.Item>
      )}
    />
  );
};

export default UserList;
