import { Card, Empty, Table } from "antd";
import { useEffect } from "react";
import DetailModal from "../Components/Modals/ShowUserDetail.modal";
import { useAppDispatch, useAppSelector } from "../Redux/hook";
import { fetchAllUsers } from "../Redux/User/User.action";
// import { PostData, PostVars } from "../Types/Post.type";
// import { DELETE_POST } from "../Utils/Mutation";

const UsersPage = () => {
  // const dispatch = useAppDispatch();
  const { users } = useAppSelector((state) => state)


  // useEffect(() => {
  //   dispatch(fetchAllUsers());
  // }, []);
 

  if (users.fetchAll.error)
    return (
      <div style={{ height: "89vh" }} className="d-flex justify-content-center">
        <Empty className="pt-4" description={users.fetchAll.error} />
      </div>
    );
  return (
    <div style={{ height: "89vh" }} className="d-flex justify-content-center">
      <Card className="w-75" loading={users.fetchAll.isLoading}>
        <Table
          columns={[
            { title: "No", render: (record, data, index) => index + 1 },
            { title: "Name", dataIndex: "username" },
            { title: "Email", dataIndex: "email" },
            {
              title: "Action",
              render: (record, data, index) => <DetailModal user={data} />
            },
          ]}
          dataSource={users.fetchAll.payload}
          pagination={false}
          size="small"
          loading={users.fetchAll.isLoading}
        />
        
      </Card>
    </div>
  );
};

export default UsersPage;
