import { put, takeLatest } from "redux-saga/effects";
import { AnnouncementActionTypes } from "./Announcement.type";
import axios from "axios";
import { baseUrl, headerAuthentication } from "../util"; 

export function* fetchAllAnnouncements(arg: any): any {
    try {
        const response = yield axios.get(`${baseUrl}/announcements`, headerAuthentication());
        yield put({type: AnnouncementActionTypes.FETCH_ALL_ANNOUNCEMENTS_SUCCESS, payload: response.data})
    }catch(err) {
        yield put({type: AnnouncementActionTypes.FETCH_ALL_ANNOUNCEMENTS_FAILURE, payload: err})
    }
}

export function* watcherFetchAllAnnouncements() {
    yield takeLatest(AnnouncementActionTypes.FETCH_ALL_ANNOUNCEMENTS, fetchAllAnnouncements);
}
