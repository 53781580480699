import React from "react";
import { Result } from "antd";

const PageNotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "90vh",
        backgroundColor: "#f0f2f5",
        justifyContent: "center",
      }}
    >
      <Result status="404" title="Page not found" />
    </div>
  );
};

export default PageNotFound;
