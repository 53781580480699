import { UserOutlined } from "@ant-design/icons";
import { Button, List, Modal } from "antd";
import { FC, useState } from "react";
import { User } from "../../Redux/User/User.type";

const MemberListModal: FC<{ members: User[] }> = ({ members }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button type="text" onClick={() => setOpen(true)}>
        Members
      </Button>
      <Modal
        visible={open}
        width={1200}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        <List
          itemLayout="horizontal"
          dataSource={members ?? []}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<UserOutlined style={{ color: "blue" }} />}
                title={item.username}
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default MemberListModal;
