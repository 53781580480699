import { ActionType, resetApiCallState } from "../util";
import { AnnouncementActionTypes, AnnouncementStateTypes } from "./Announcement.type";

const INITIAL_STATE: AnnouncementStateTypes = {
    fetchAll: resetApiCallState([]),
};

const AnnouncementReducer = (state: AnnouncementStateTypes = INITIAL_STATE, action: ActionType): AnnouncementStateTypes => {
    switch (action.type) {
        case AnnouncementActionTypes.FETCH_ALL_ANNOUNCEMENTS:
            return { ...state, fetchAll: { error: null, payload: [], isLoading: true, isLoaded: false } };
        case AnnouncementActionTypes.FETCH_ALL_ANNOUNCEMENTS_RESET:
            return { ...state, fetchAll: resetApiCallState([]) };
        case AnnouncementActionTypes.FETCH_ALL_ANNOUNCEMENTS_FAILURE:
            return { ...state, fetchAll: { error: action.payload, payload: [], isLoading: false, isLoaded: true } };
        case AnnouncementActionTypes.FETCH_ALL_ANNOUNCEMENTS_SUCCESS:
            return { ...state, fetchAll: { error: null, payload: action.payload, isLoading: false, isLoaded: true } };
        default:
            return state;
    }
}

export default AnnouncementReducer;