import { List } from "antd";
import React, { FC } from "react";
import { Group } from "../Redux/User/User.type";

const GroupList: FC<{groups: Group[]}> = ({groups}) => {
    return (
        <List
          itemLayout="horizontal"
          dataSource={groups ?? []}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={item.name}
              />
            </List.Item>
          )}
        />
      );
};

export default GroupList;