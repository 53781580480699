import { Switch } from "antd";
import react, { FC } from "React";

const SettingComponent: FC<{}> = ({}) => {
  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };
  return (
    <div className="row">
      <div className="col-md-2">Status</div>
      <div className="col-md-3">
        <Switch
          defaultChecked
          onChange={onChange}
          checkedChildren="Enabled"
          unCheckedChildren="Disabled"
          loading={true}
        />
      </div>
    </div>
  );
};

export default SettingComponent;
