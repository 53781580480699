import { ActionType, resetApiCallState } from "../util";
import { GroupActionTypes, GroupStateTypes } from "./Group.type";

const INITIAL_STATE: GroupStateTypes = {
    fetchAll: resetApiCallState([]),
};

const AnnouncementReducer = (state: GroupStateTypes = INITIAL_STATE, action: ActionType): GroupStateTypes => {
    switch (action.type) {
        case GroupActionTypes.FETCH_ALL_GROUP:
            return { ...state, fetchAll: { error: null, payload: [], isLoading: true, isLoaded: false } };
        case GroupActionTypes.FETCH_ALL_GROUP_RESET:
            return { ...state, fetchAll: resetApiCallState([]) };
        case GroupActionTypes.FETCH_ALL_GROUP_FAILURE:
            return { ...state, fetchAll: { error: action.payload, payload: [], isLoading: false, isLoaded: true } };
        case GroupActionTypes.FETCH_ALL_GROUP_SUCCESS:
            return { ...state, fetchAll: { error: null, payload: action.payload, isLoading: false, isLoaded: true } };
        default:
            return state;
    }
}

export default AnnouncementReducer;