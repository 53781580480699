import React, { FC, useState } from "react";
import { Button, List, notification, Popconfirm } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { DeleteOutlined, MessageFilled } from "@ant-design/icons";
import axios from "axios";
import { baseUrl, headerAuthentication } from "../Redux/util";
import { useAppDispatch } from "../Redux/hook";
import { fetchAllUsers } from "../Redux/User/User.action";
import AnnouncementDetailModal from "./Modals/AnnouncementDetail.modal";
import { Announcements } from "../Redux/Announcement/Announcement.type";

const AnnouncementList: FC<{ announcements: Announcements[] }> = ({
  announcements,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onDelete = (id: number) => {
    setLoading(true);
    axios
      .delete(baseUrl + `/announcement/${id}`, headerAuthentication())
      .then(() => {
        setLoading(false);
        dispatch(fetchAllUsers());
        notification.success({
          message: "Announcement deleted",
        });
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: "Announcement delete failed",
        });
      });
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={announcements ?? []}
      renderItem={(item) => (
        <List.Item
          actions={[
            <AnnouncementDetailModal announcement={item} />,
            <Popconfirm
              title="Are you sure to delete this announcement?"
              onConfirm={() => onDelete(item.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                loading={loading}
                danger
              ></Button>
            </Popconfirm>
          ]}
        >
          <List.Item.Meta
            avatar={<MessageFilled style={{ color: "orange" }} />}
            title={item.description}
            description={<Paragraph>{item?.description}</Paragraph>}
          />
        </List.Item>
      )}
    />
  );
};

export default AnnouncementList;
