import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Empty, notification, Popconfirm, Table } from "antd";
import axios from "axios";
import { FC, useState } from "react";
import AnnouncementDetailWMemberModal from "../Components/Modals/AnnouncementDetailWMember.modal";
import { fetchAllAnnouncements } from "../Redux/Announcement/Announcement.action";
import { useAppDispatch, useAppSelector } from "../Redux/hook";
import { baseUrl, headerAuthentication } from "../Redux/util";

const AnnouncementPage: FC<{}> = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { announcements } = useAppSelector((state) => state);

  // useEffect(() => {
  //   dispatch(fetchAllAnnouncements());
  // }, []);

  const onDelete = (id: number) => {
    setLoading(true);
    axios
      .delete(baseUrl + `/announcement/${id}`, headerAuthentication())
      .then(() => {
        setLoading(false);
        dispatch(fetchAllAnnouncements());
        notification.success({
          message: "Announcement deleted",
        });
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: "Announcement delete failed",
        });
      });
  };

  if (announcements.fetchAll.error)
    return (
      <div style={{ height: "89vh" }} className="d-flex justify-content-center">
        <Empty className="pt-4" description={announcements.fetchAll.error} />
      </div>
    );
  return (
    <div style={{ height: "89vh" }} className="d-flex justify-content-center">
      <Card className="w-75" loading={announcements.fetchAll.isLoading}>
        <Table
          columns={[
            { title: "No", render: (record, data, index) => index + 1 },
            { title: "Description", dataIndex: "description" },
            {
              title: "Action",
              render: (record, data, index) => (
                <div>
                  <AnnouncementDetailWMemberModal announcement={data} />
                  <Popconfirm
                    title="Are you sure to delete this announcement?"
                    onConfirm={() => onDelete(data.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                    type="text"
                      icon={<DeleteOutlined />}
                      loading={loading}
                      danger
                    >Delete</Button>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
          dataSource={announcements.fetchAll.payload}
          pagination={false}
          size="small"
          loading={announcements.fetchAll.isLoading}
        />
      </Card>
    </div>
  );
};

export default AnnouncementPage;
