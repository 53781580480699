import {
  AudioOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Statistic } from "antd";
import { useEffect } from "react";
import { fetchAllAnnouncements } from "../Redux/Announcement/Announcement.action";
import { fetchAllGroups } from "../Redux/Group/Group.action";
import { useAppDispatch, useAppSelector } from "../Redux/hook";
import { fetchAllUsers } from "../Redux/User/User.action";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const HomePage = ({}) => {
  const { users, announcements, groups } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchAllAnnouncements());
    dispatch(fetchAllGroups());
  }, []);

  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  return (
    <div style={{ paddingLeft: 150, paddingRight: 150, height: "89vh" }}>
      <div className="row">
        <div className="col-md-4">
          <Statistic
            title="Users"
            value={users.fetchAll.payload.length}
            prefix={<UserOutlined />}
            loading={
              users.fetchAll.isLoading ||
              announcements.fetchAll.isLoading ||
              groups.fetchAll.isLoading
            }
          />
        </div>
        <div className="col-md-4">
          <Statistic
            title="Announcements"
            value={announcements.fetchAll.payload.length}
            prefix={<AudioOutlined />}
            loading={
              users.fetchAll.isLoading ||
              announcements.fetchAll.isLoading ||
              groups.fetchAll.isLoading
            }
          />
        </div>
        <div className="col-md-4">
          <Statistic
            title="Groups"
            value={groups.fetchAll.payload.length}
            prefix={<UsergroupAddOutlined />}
            loading={
              users.fetchAll.isLoading ||
              announcements.fetchAll.isLoading ||
              groups.fetchAll.isLoading
            }
          />
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-md-12">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={200}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
