import { all, call } from "redux-saga/effects";
import { watcherFetchAllUsers } from "./User/User.saga";
import { watcherFetchAllAnnouncements } from "./Announcement/Announcement.saga";
import { watcherFetchAllGroups } from "./Group/Group.saga";

function* rootSaga() {
  yield all([
    call(watcherFetchAllUsers),
    call(watcherFetchAllAnnouncements),
    call(watcherFetchAllGroups),
  ]);
}

export default rootSaga;
