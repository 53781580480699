import { Button, Modal, Image, Descriptions, Badge } from "antd";
import { FC, useState } from "react";
import { User } from "../../Redux/User/User.type";
import { IMAGE_URL } from "../../Utils/Constants";

const UserDetailModal: FC<{ user: User }> = ({ user }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button type="text" onClick={() => setOpen(true)}>
        {user.username}
      </Button>
      <Modal
        visible={open}
        width={1200}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        <div>
          <Image
            width={200}
            src={`${IMAGE_URL}/${user.profileImage}`}
            fallback={
              "https://andersnoren.se/themes/koji/wp-content/themes/koji/assets/images/default-fallback-image.png"
            }
          />
          <Descriptions title="">
            <Descriptions.Item label="User name">
              {user.username}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
            <Descriptions.Item label="Bio">{user.bio}</Descriptions.Item>
            <Descriptions.Item label="Online">
              {user.online ? (
                <Badge color="green">Online</Badge>
              ) : (
                <Badge color="red">Offline</Badge>
              )}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Modal>
    </>
  );
};

export default UserDetailModal;
