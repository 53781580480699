import React from "react";
import { Routes, Route } from "react-router-dom";
import MainContainer from "./Container/main.container";
import PageNotFound from "./Pages/404.page";
import AnnouncementPage from "./Pages/Announcement.page";
import GroupPage from "./Pages/Group.page";
import HomePage from "./Pages/Home.page";
import LogoutPage from "./Pages/Login.page";
import UsersPage from "./Pages/Users.page";
import ProtectedRoute from "./Utils/ProtectedRoute";
function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
<MainContainer>
            <HomePage />
          </MainContainer>
          </ProtectedRoute>
          
        }
      />
      <Route
        path="/login"
        element={
          <MainContainer>
            <LogoutPage />
          </MainContainer>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <MainContainer>
              <UsersPage />
            </MainContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/announcements"
        element={
          <ProtectedRoute>
            <MainContainer>
              <AnnouncementPage />
            </MainContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/groups"
        element={
          <ProtectedRoute>
            <MainContainer>
              <GroupPage />
            </MainContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          <MainContainer>
            <PageNotFound />
          </MainContainer>
        }
      />
    </Routes>
  );
}

export default App;
