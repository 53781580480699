import { Announcements } from "../Announcement/Announcement.type";
import { ApiCallState } from "../util";

export type Group = {
    id: number,
    name: string,
};


export type User = {
    id: number,
    uid: string,
    username: string,
    bio: string,
    profileImage: string,
    email: string,
    online: boolean,
    createdAt: Date,
    updatedAt: Date,
    userFriends: User[],
    friends: User[],
    UserFriend: {
        user_id: number,
        friend_id: number,
        isAccepted: boolean,
        userVisible: boolean,
        friendVisible: boolean,
        createdAt: Date,
        updatedAt: Date
    }
    groups: Group[],
    announcements: Announcements[],
    uannouncements: Announcements[],
};

export type UserStateTypes = {
    fetchAll: ApiCallState<User[]>,
};

export const UserActionTypes = {
    FETCH_ALL_USERS: "FETCH_ALL_USERS",
    FETCH_ALL_USERS_RESET: "FETCH_ALL_USERS_RESET",
    FETCH_ALL_USERS_FAILURE: "FETCH_ALL_USERS_FAILURE",
    FETCH_ALL_USERS_SUCCESS: "FETCH_ALL_USERS_SUCCESS",
}