import { Component, FC } from "react"
import { Link, Navigate } from "react-router-dom";
import { LOCAL_TOKEN } from "./Constants"

const ProtectedRoute: FC<{children: any}> = ({children}) => {
    const token = localStorage.getItem(LOCAL_TOKEN);
    if (!token) return <Navigate to="/login" replace />
    return children
}

export default ProtectedRoute