import { combineReducers } from "redux";
import UsersReducer from "./User/User.reducer";
import AnnouncementReducer from "./Announcement/Announcement.reducer";
import GroupReducer from "./Group/Group.reducer";

const rootReducer = combineReducers({
  users: UsersReducer,
  announcements: AnnouncementReducer,
  groups: GroupReducer,
});

export default rootReducer;
