import { User } from "../User/User.type";
import { ApiCallState } from "../util";

export type Group = {
    id: number,
    name: string,
    emoji: string,
    members: User[],
    owner: User,
};

export type GroupStateTypes = {
    fetchAll: ApiCallState<Group[]>,
};

export const GroupActionTypes = {
    FETCH_ALL_GROUP: "FETCH_ALL_GROUP",
    FETCH_ALL_GROUP_RESET: "FETCH_ALL_GROUP_RESET",
    FETCH_ALL_GROUP_FAILURE: "FETCH_ALL_GROUP_FAILURE",
    FETCH_ALL_GROUP_SUCCESS: "FETCH_ALL_GROUP_SUCCESS",
}