import {
  AudioOutlined,
  LoginOutlined,
  LogoutOutlined,
  PlusOutlined, UsergroupAddOutlined, UserOutlined
} from "@ant-design/icons";
import { Button, Layout } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { CURRENT_ID, LOCAL_TOKEN } from "../Utils/Constants";
const { Header, Content } = Layout;

const App: FC<{ children: any }> = ({ children }) => {
  const token = localStorage.getItem(LOCAL_TOKEN);
  const navigate = useNavigate();
  const whenLoggedOut = [
    { icon: <LoginOutlined />, name: "Login", path: "/login" },
  ].map((item, index) => (
    <Button
      icon={item.icon}
      style={{ color: "white" }}
      type="text"
      onClick={() => navigate(item.path)}
      key={index}
    >
      {item.name}
    </Button>
  ));

  const whenLoggedIn = [
    { icon: <UserOutlined />, name: "Users", path: "users" },
    { icon: <AudioOutlined />, name: "Announcement", path: "announcements" },
    { icon: <UsergroupAddOutlined />, name: "Groups", path: "groups" },
    { icon: <LogoutOutlined />, name: "Logout", path: "logout" },
  ].map((item, index) => (
    <Button
      key={index}
      icon={item.icon}
      style={{ color: "white" }}
      type="text"
      onClick={() => {
        if (item.name === "Logout") {
          localStorage.removeItem(LOCAL_TOKEN)
          localStorage.removeItem(CURRENT_ID)
          navigate("/login")
        }else {
          navigate(`/${item.path}`)
        }
      }}
    >
      {item.name}
    </Button>
  ));


  return (
    <Layout>
      <Header className="header">
        <div
        
          style={{
            float: "left",
            paddingRight: 50,
          }}
        >
          <Button type="text" style={{ color: "white", fontSize: 25 }} onClick={() => navigate("/")}>KLIQ</Button>
        </div>
        <div style={{ float: "right" }}>
          {token ? whenLoggedIn : whenLoggedOut}
        </div>
      </Header>
      <Content style={{ margin: "24px 16px 0", width: "100%", height: "100%" }}>
        {children}
      </Content>
    </Layout>
  );
};

export default App;
