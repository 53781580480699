import React, { FC } from "react";
import { Image, Descriptions, Badge } from "antd";
import { User } from "../Redux/User/User.type";
import { IMAGE_URL } from "../Utils/Constants";

const UserDetailComponent: FC<{ user: User }> = ({ user }) => {
  return (
    <div>
      <Image
        width={200}
        src={`${IMAGE_URL}/${user.profileImage}`}
        fallback={"https://andersnoren.se/themes/koji/wp-content/themes/koji/assets/images/default-fallback-image.png"}
      />
      <Descriptions title="">
        <Descriptions.Item label="User name">{user.username}</Descriptions.Item>
        <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
        <Descriptions.Item label="Bio">{user.bio}</Descriptions.Item>
        <Descriptions.Item label="Online">
          {user.online ? (
            <Badge color="green">Online</Badge>
          ) : (
            <Badge color="red">Offline</Badge>
          )}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default UserDetailComponent;
