import React, { FC, useState } from "react";
import { Button, Descriptions, Image, List, Modal, Tabs } from "antd";
import { MoreOutlined, UserOutlined } from "@ant-design/icons";
import { IMAGE_URL } from "../../Utils/Constants";
import moment from "moment";
import { Announcements } from "../../Redux/Announcement/Announcement.type";

const AnnouncementDetailWMemberModal: FC<{ announcement: Announcements }> = ({
  announcement,
}) => {
  const [open, setOpen] = useState(false);
  console.log("Announcement >>>  ", announcement);
  return (
    <>
      <Button icon={<MoreOutlined />} type="text" onClick={() => setOpen(true)}>
        Content
      </Button>
      <Modal
        visible={open}
        width={1200}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        <Tabs>
          <Tabs.TabPane tab="Detail" key={1}>
            <div className="row">
              <div style={{ textAlign: "center" }}>
                <Image
                  width={200}
                  src={`${IMAGE_URL}/${announcement.image}`}
                  fallback={
                    "https://andersnoren.se/themes/koji/wp-content/themes/koji/assets/images/default-fallback-image.png"
                  }
                />
              </div>
              {announcement.audio && (
                <div style={{ textAlign: "center", paddingTop: 10 }}>
                  <a href={`${IMAGE_URL}/${announcement.audio}`}>Link</a>
                </div>
              )}
              <div className="pt-2">
                <Descriptions title="">
                  <Descriptions.Item label="Detail">
                    {announcement.description}
                  </Descriptions.Item>
                  <Descriptions.Item label="Location">{`longitude: ${announcement.longitude} - latitude: ${announcement.latitude}`}</Descriptions.Item>
                  <Descriptions.Item label="Creator">{`${announcement?.creator?.username}`}</Descriptions.Item>
                  <Descriptions.Item label="Created At">
                    {moment(announcement.createdAt).format("YYYY-MM-DD")}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="To ..." key={2}>
            <List
              itemLayout="horizontal"
              dataSource={announcement.participators ?? []}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<UserOutlined style={{ color: "blue" }} />}
                    title={item.username}
                  />
                </List.Item>
              )}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default AnnouncementDetailWMemberModal;
