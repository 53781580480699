import { put, takeLatest } from "redux-saga/effects";
import { UserActionTypes } from "./User.type";
import axios from "axios";
import { baseUrl, headerAuthentication } from "../util"; 

export function* fetchAllUsers(arg: any): any {
    try {
        const response = yield axios.get(`${baseUrl}/users`, headerAuthentication());
        yield put({type: UserActionTypes.FETCH_ALL_USERS_SUCCESS, payload: response.data})
    }catch(err) {
        yield put({type: UserActionTypes.FETCH_ALL_USERS_FAILURE, payload: err})
    }
}

export function* watcherFetchAllUsers() {
    yield takeLatest(UserActionTypes.FETCH_ALL_USERS, fetchAllUsers);
}
