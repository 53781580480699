import { put, takeLatest } from "redux-saga/effects";
import { GroupActionTypes } from "./Group.type";
import axios from "axios";
import { baseUrl, headerAuthentication } from "../util"; 

export function* fetchAllGroups(arg: any): any {
    try {
        const response = yield axios.get(`${baseUrl}/groups`, headerAuthentication());
        yield put({type: GroupActionTypes.FETCH_ALL_GROUP_SUCCESS, payload: response.data})
    }catch(err) {
        yield put({type: GroupActionTypes.FETCH_ALL_GROUP_FAILURE, payload: err})
    }
}

export function* watcherFetchAllGroups() {
    yield takeLatest(GroupActionTypes.FETCH_ALL_GROUP, fetchAllGroups);
}
