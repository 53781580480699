import { Card, Empty, Table } from "antd";
import { FC } from "react";
import MemberListModal from "../Components/Modals/MemeberList.modal";
import UserDetailModal from "../Components/Modals/UserDetail.modal";
import { useAppSelector } from "../Redux/hook";

const GroupPage: FC<{}> = ({}) => {
  // const dispatch = useAppDispatch();
  const { groups } = useAppSelector((state) => state);

  // useEffect(() => {
  //   dispatch(fetchAllGroups());
  // }, []);

  if (groups.fetchAll.error)
    return (
      <div style={{ height: "89vh" }} className="d-flex justify-content-center">
        <Empty className="pt-4" description={groups.fetchAll.error} />
      </div>
    );
  return (
    <div style={{ height: "89vh" }} className="d-flex justify-content-center">
      <Card className="w-75" loading={groups.fetchAll.isLoading}>
        <Table
          columns={[
            { title: "No", render: (record, data, index) => index + 1 },
            { title: "Description", dataIndex: "description" },
            {
              title: "Owner",
              render: (record, data, index) => (
                <UserDetailModal user={data.owner} />
              ),
            },
            {
                title: "Memebers",
                render: (record, data, index) => (<MemberListModal members={data.members} />)
            }
          ]}
          dataSource={groups.fetchAll.payload}
          pagination={false}
          size="small"
          loading={groups.fetchAll.isLoading}
        />
      </Card>
    </div>
  );
};

export default GroupPage;
