import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const store = configureStore({reducer: rootReducer, middleware: [sagaMiddleware]})
  sagaMiddleware.run(rootSaga)
  return store   
}

export const store = makeStore();
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

