import { ActionType, resetApiCallState } from "../util";
import { UserActionTypes, UserStateTypes } from "./User.type";

const INITIAL_STATE: UserStateTypes = {
    fetchAll: resetApiCallState([]),
};

const UserReducer = (state: UserStateTypes = INITIAL_STATE, action: ActionType): UserStateTypes => {
    switch (action.type) {
        case UserActionTypes.FETCH_ALL_USERS:
            return { ...state, fetchAll: { error: null, payload: [], isLoading: true, isLoaded: false } };
        case UserActionTypes.FETCH_ALL_USERS_RESET:
            return { ...state, fetchAll: resetApiCallState([]) };
        case UserActionTypes.FETCH_ALL_USERS_FAILURE:
            return { ...state, fetchAll: { error: action.payload, payload: [], isLoading: false, isLoaded: true } };
        case UserActionTypes.FETCH_ALL_USERS_SUCCESS:
            return { ...state, fetchAll: { error: null, payload: action.payload, isLoading: false, isLoaded: true } };
        default:
            return state;
    }
}

export default UserReducer;