import { Button, Card, Form, Input } from "antd";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LOCAL_TOKEN } from "../Utils/Constants";

const LogoutPage: FC<{}> = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    setError("");
    console.log("this is going to be called when the form is submitted");
    console.log("VaLUES >>>  ", values);
    if (values.username === "admin" && values.password === "admin") {
      setError("")
      localStorage.setItem(LOCAL_TOKEN, "--- something random ---");
      navigate("/")
    }else {
      setError("User name or password is incorrect");
    }
  }

  return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          backgroundColor: "#f0f2f5",
          justifyContent: "center", 
        }}
      >
        <Card title="Login" className="w-25 h-50 mt-5" bordered={false} style={{ width: 300 }}>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="User name"
              name="username"
              rules={[
                { required: true, message: "User name is required" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
            {error !== "" && <p style={{color: "red"}}>{error}</p>}
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" className="mr-2" loading={false}>
                Submit
              </Button>
              
            </Form.Item>
            {/* <div style={{textAlign: "center"}}><Link to="/signup"><a href="">register now!</a></Link></div> */}
            
          </Form>
        </Card>
      </div>
  );
};

export default LogoutPage;
