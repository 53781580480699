import { User } from "../User/User.type";
import { ApiCallState } from "../util";

export type Group = {
    id: number,
    name: string,
};

export type Announcements = {
    id: number,
    latitude: string,
    longitude: string,
    description: string,
    image?: string,
    audio?: string,
    createdAt: Date,
    updatedAt: Date,
    UserAnnouncement?: {
        isSeen: boolean,
        createdAt: Date,
        updatedAt: Date,
        UserId: number
    },
    ugroups: Group[],
    creator: User,
    participators: User[]
}

export type AnnouncementStateTypes = {
    fetchAll: ApiCallState<Announcements[]>,
};

export const AnnouncementActionTypes = {
    FETCH_ALL_ANNOUNCEMENTS: "FETCH_ALL_ANNOUNCEMENTS",
    FETCH_ALL_ANNOUNCEMENTS_RESET: "FETCH_ALL_ANNOUNCEMENTS_RESET",
    FETCH_ALL_ANNOUNCEMENTS_FAILURE: "FETCH_ALL_ANNOUNCEMENTS_FAILURE",
    FETCH_ALL_ANNOUNCEMENTS_SUCCESS: "FETCH_ALL_ANNOUNCEMENTS_SUCCESS",
}